<template>
  <div>
    <v-row>
      <v-col>
        <ImportXlsxField url="product/parse-xlsx" @input="handleXlsx($event)" />
      </v-col>
    </v-row>

    <template v-if="hasProducts">
      <v-row>
        <v-col>
          <app-text-search-field @input="handleSearchInput($event)" />
        </v-col>
        <v-col>
          <app-text-search-field
            label="Código"
            @input="handleSearchCode($event)"
          />
        </v-col>

        <v-col class="d-flex justify-end">
          <v-btn @click="updateProducts()" color="success"> Atualizar </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            :headers="headers"
            :items="products"
            :items-per-page="-1"
            :mobile-breakpoint="0"
            hide-default-footer
            disable-sort
            dense
          >
            <template v-slot:[`item.new_brand_code`]="{ item }">
              <b class="success--text">
                {{ item.new_brand_code }}
              </b>
              <br />
              <small>
                {{ item.brand_id }}
              </small>
            </template>
            <template v-slot:[`item.new_supplier_code`]="{ item }">
              <b class="success--text">
                {{ item.new_supplier_code }}
              </b>
              <br />
              <small>
                {{ item.supplier_id }}
              </small>
            </template>
            <template v-slot:[`item.new_category_code`]="{ item }">
              <b class="success--text">
                {{ item.new_category_code }}
              </b>
              <br />
              <small>
                {{ item.category_id }}
              </small>
            </template>
            <template v-slot:[`item.new_purchase_price`]="{ item }">
              <b class="success--text">
                {{ $format.decimal(item.new_purchase_price) }}
              </b>
              <br />
              <small>
                {{ $format.decimal(item.product_variants[0].purchase_price) }}
              </small>
            </template>
            <template v-slot:[`item.new_st`]="{ item }">
              <b class="success--text"> {{ $format.decimal(item.new_st) }}</b>
              <br />
              <small> {{ $format.decimal(item.product_variants[0].st) }}</small>
            </template>
            <template v-slot:[`item.new_ipi`]="{ item }">
              <b class="success--text">{{ $format.decimal(item.new_ipi) }} </b>
              <br />
              <small>{{ $format.decimal(item.product_variants[0].ipi) }}</small>
            </template>
            <template v-slot:[`item.new_recomposicao_aliquota`]="{ item }">
              <b class="success--text">
                {{ $format.decimal(item.new_recomposicao_aliquota) }}
              </b>
              <br />
              <small>
                {{
                  $format.decimal(
                    item.product_variants[0].recomposicao_aliquota
                  )
                }}
              </small>
            </template>
            <template v-slot:[`item.new_shipping`]="{ item }">
              <b class="success--text">
                {{ $format.decimal(item.new_shipping) }}
              </b>
              <br />
              <small>
                {{ $format.decimal(item.product_variants[0].shipping) }}
              </small>
            </template>
            <template v-slot:[`item.new_cost`]="{ item }">
              <b class="success--text">{{ $format.decimal(item.new_cost) }} </b>
              <br />
              <small>
                {{ $format.decimal(item.product_variants[0].cost) }}
              </small>
            </template>
            <template v-slot:[`item.new_icms_debt`]="{ item }">
              <b class="success--text">
                {{ $format.decimal(item.new_icms_debt) }}
              </b>
              <br />
              <small>
                {{ $format.decimal(item.product_variants[0].icms_debt) }}
              </small>
            </template>
            <template v-slot:[`item.new_icms_credit`]="{ item }">
              <b class="success--text">
                {{ $format.decimal(item.new_icms_credit) }}
              </b>
              <br />
              <small>
                {{ $format.decimal(item.product_variants[0].icms_credit) }}
              </small>
            </template>
            <template v-slot:[`item.new_pis`]="{ item }">
              <b class="success--text">{{ $format.decimal(item.new_pis) }}</b>
              <br />
              <small>{{ $format.decimal(item.product_variants[0].pis) }}</small>
            </template>
            <template v-slot:[`item.new_cofins`]="{ item }">
              <b class="success--text">
                {{ $format.decimal(item.new_cofins) }}
              </b>
              <br />
              <small>
                {{ $format.decimal(item.product_variants[0].cofins) }}
              </small>
            </template>
            <template v-slot:[`item.new_irpj`]="{ item }">
              <b class="success--text">{{ $format.decimal(item.new_irpj) }}</b>
              <br />
              <small>
                {{ $format.decimal(item.product_variants[0].irpj) }}
              </small>
            </template>
            <template v-slot:[`item.new_csll`]="{ item }">
              <b class="success--text">{{ $format.decimal(item.new_csll) }}</b>
              <br />
              <small>
                {{ $format.decimal(item.product_variants[0].csll) }}
              </small>
            </template>
            <template v-slot:[`item.new_simples_nacional`]="{ item }">
              <b class="success--text">
                {{ $format.decimal(item.new_simples_nacional) }}
              </b>
              <br />
              <small>
                {{ $format.decimal(item.product_variants[0].simples_nacional) }}
              </small>
            </template>
            <template v-slot:[`item.new_commission`]="{ item }">
              <b class="success--text">
                {{ $format.decimal(item.new_commission) }}
              </b>
              <br />
              <small>
                {{ $format.decimal(item.product_variants[0].commission) }}
              </small>
            </template>
            <template v-slot:[`item.new_price_cash`]="{ item }">
              <b class="success--text">
                {{ $format.decimal(item.new_price_cash) }}
              </b>
              <br />
              <small>
                {{ $format.decimal(item.product_variants[0].price_cash) }}
              </small>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
import ImportXlsxField from "@/components/product/ui/ImportXlsxField.vue";
export default {
  components: { ImportXlsxField },

  data() {
    return {
      products: [],
      headers: [
        { align: "center", value: "id", text: "ID" },
        { align: "center", value: "code", text: "COD" },
        { value: "name", text: "Nome" },
        { value: "new_short_name", text: "Nome curto" },
        { value: "new_brand_code", text: "Cod. Marca" },
        { value: "new_supplier_code", text: "Cod. Fornecedor" },
        { value: "new_category_code", text: "Cod. Categoria" },
        { value: "new_ncm", text: "NCM" },
        { value: "new_cest", text: "CEST" },
        { value: "new_origem", text: "Origem" },
        { value: "new_classificacao_fiscal", text: "Classificação fiscal" },

        {
          align: "center",
          value: "new_purchase_price",
          text: "P.Compra",
        },
        { align: "center", value: "new_st", text: "ST" },
        { align: "center", value: "new_ipi", text: "IPI" },
        {
          align: "center",
          value: "new_recomposicao_aliquota",
          text: "Re. Aliquota",
        },
        { align: "center", value: "new_shipping", text: "Frete" },
        { align: "center", value: "new_cost", text: "Custo" },
        { align: "center", value: "new_icms_debt", text: "ICMS Saída" },
        { align: "center", value: "new_icms_credit", text: "ICMS Entrada" },
        { align: "center", value: "new_pis", text: "PIS" },
        { align: "center", value: "new_cofins", text: "COFINS" },
        { align: "center", value: "new_irpj", text: "IRPJ" },
        { align: "center", value: "new_csll", text: "CSLL" },
        {
          align: "center",
          value: "new_simples_nacional",
          text: "Simples Nacional",
        },
        { align: "center", value: "new_commission", text: "Comissão" },
        { align: "center", value: "new_price_cash", text: "P. A Vista" },
        { align: "center", value: "new_status", text: "Status" },
      ],
    };
  },

  computed: {
    hasProducts() {
      return this.products.length > 0;
    },
  },

  methods: {
    handleXlsx(data) {
      this.products = JSON.parse(JSON.stringify(data.products));
    },
    updateProducts() {
      this.$loading.start();
      this.$http
        .store("product/mass-update", { products: this.products })
        .then((response) => {
          this.$loading.finish();
          this.$snackbar({
            message: "Produtos atualizados com sucesso!",
            props: {
              color: "success",
              timeout: 10000,
            },
          });
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },

    handleSearchInput(text) {
      this.products = this.products.filter((product) => {
        return product.name.toLowerCase().includes(text.toLowerCase());
      });
    },

    handleSearchCode(text) {
      this.products = this.products.filter((product) => {
        return product.code == text;
      });
    },
  },
};
</script>

<style>
</style>