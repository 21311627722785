var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',[_c('ImportXlsxField',{attrs:{"url":"product/parse-xlsx"},on:{"input":function($event){return _vm.handleXlsx($event)}}})],1)],1),(_vm.hasProducts)?[_c('v-row',[_c('v-col',[_c('app-text-search-field',{on:{"input":function($event){return _vm.handleSearchInput($event)}}})],1),_c('v-col',[_c('app-text-search-field',{attrs:{"label":"Código"},on:{"input":function($event){return _vm.handleSearchCode($event)}}})],1),_c('v-col',{staticClass:"d-flex justify-end"},[_c('v-btn',{attrs:{"color":"success"},on:{"click":function($event){return _vm.updateProducts()}}},[_vm._v(" Atualizar ")])],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.products,"items-per-page":-1,"mobile-breakpoint":0,"hide-default-footer":"","disable-sort":"","dense":""},scopedSlots:_vm._u([{key:"item.new_brand_code",fn:function(ref){
var item = ref.item;
return [_c('b',{staticClass:"success--text"},[_vm._v(" "+_vm._s(item.new_brand_code)+" ")]),_c('br'),_c('small',[_vm._v(" "+_vm._s(item.brand_id)+" ")])]}},{key:"item.new_supplier_code",fn:function(ref){
var item = ref.item;
return [_c('b',{staticClass:"success--text"},[_vm._v(" "+_vm._s(item.new_supplier_code)+" ")]),_c('br'),_c('small',[_vm._v(" "+_vm._s(item.supplier_id)+" ")])]}},{key:"item.new_category_code",fn:function(ref){
var item = ref.item;
return [_c('b',{staticClass:"success--text"},[_vm._v(" "+_vm._s(item.new_category_code)+" ")]),_c('br'),_c('small',[_vm._v(" "+_vm._s(item.category_id)+" ")])]}},{key:"item.new_purchase_price",fn:function(ref){
var item = ref.item;
return [_c('b',{staticClass:"success--text"},[_vm._v(" "+_vm._s(_vm.$format.decimal(item.new_purchase_price))+" ")]),_c('br'),_c('small',[_vm._v(" "+_vm._s(_vm.$format.decimal(item.product_variants[0].purchase_price))+" ")])]}},{key:"item.new_st",fn:function(ref){
var item = ref.item;
return [_c('b',{staticClass:"success--text"},[_vm._v(" "+_vm._s(_vm.$format.decimal(item.new_st)))]),_c('br'),_c('small',[_vm._v(" "+_vm._s(_vm.$format.decimal(item.product_variants[0].st)))])]}},{key:"item.new_ipi",fn:function(ref){
var item = ref.item;
return [_c('b',{staticClass:"success--text"},[_vm._v(_vm._s(_vm.$format.decimal(item.new_ipi))+" ")]),_c('br'),_c('small',[_vm._v(_vm._s(_vm.$format.decimal(item.product_variants[0].ipi)))])]}},{key:"item.new_recomposicao_aliquota",fn:function(ref){
var item = ref.item;
return [_c('b',{staticClass:"success--text"},[_vm._v(" "+_vm._s(_vm.$format.decimal(item.new_recomposicao_aliquota))+" ")]),_c('br'),_c('small',[_vm._v(" "+_vm._s(_vm.$format.decimal( item.product_variants[0].recomposicao_aliquota ))+" ")])]}},{key:"item.new_shipping",fn:function(ref){
var item = ref.item;
return [_c('b',{staticClass:"success--text"},[_vm._v(" "+_vm._s(_vm.$format.decimal(item.new_shipping))+" ")]),_c('br'),_c('small',[_vm._v(" "+_vm._s(_vm.$format.decimal(item.product_variants[0].shipping))+" ")])]}},{key:"item.new_cost",fn:function(ref){
var item = ref.item;
return [_c('b',{staticClass:"success--text"},[_vm._v(_vm._s(_vm.$format.decimal(item.new_cost))+" ")]),_c('br'),_c('small',[_vm._v(" "+_vm._s(_vm.$format.decimal(item.product_variants[0].cost))+" ")])]}},{key:"item.new_icms_debt",fn:function(ref){
var item = ref.item;
return [_c('b',{staticClass:"success--text"},[_vm._v(" "+_vm._s(_vm.$format.decimal(item.new_icms_debt))+" ")]),_c('br'),_c('small',[_vm._v(" "+_vm._s(_vm.$format.decimal(item.product_variants[0].icms_debt))+" ")])]}},{key:"item.new_icms_credit",fn:function(ref){
var item = ref.item;
return [_c('b',{staticClass:"success--text"},[_vm._v(" "+_vm._s(_vm.$format.decimal(item.new_icms_credit))+" ")]),_c('br'),_c('small',[_vm._v(" "+_vm._s(_vm.$format.decimal(item.product_variants[0].icms_credit))+" ")])]}},{key:"item.new_pis",fn:function(ref){
var item = ref.item;
return [_c('b',{staticClass:"success--text"},[_vm._v(_vm._s(_vm.$format.decimal(item.new_pis)))]),_c('br'),_c('small',[_vm._v(_vm._s(_vm.$format.decimal(item.product_variants[0].pis)))])]}},{key:"item.new_cofins",fn:function(ref){
var item = ref.item;
return [_c('b',{staticClass:"success--text"},[_vm._v(" "+_vm._s(_vm.$format.decimal(item.new_cofins))+" ")]),_c('br'),_c('small',[_vm._v(" "+_vm._s(_vm.$format.decimal(item.product_variants[0].cofins))+" ")])]}},{key:"item.new_irpj",fn:function(ref){
var item = ref.item;
return [_c('b',{staticClass:"success--text"},[_vm._v(_vm._s(_vm.$format.decimal(item.new_irpj)))]),_c('br'),_c('small',[_vm._v(" "+_vm._s(_vm.$format.decimal(item.product_variants[0].irpj))+" ")])]}},{key:"item.new_csll",fn:function(ref){
var item = ref.item;
return [_c('b',{staticClass:"success--text"},[_vm._v(_vm._s(_vm.$format.decimal(item.new_csll)))]),_c('br'),_c('small',[_vm._v(" "+_vm._s(_vm.$format.decimal(item.product_variants[0].csll))+" ")])]}},{key:"item.new_simples_nacional",fn:function(ref){
var item = ref.item;
return [_c('b',{staticClass:"success--text"},[_vm._v(" "+_vm._s(_vm.$format.decimal(item.new_simples_nacional))+" ")]),_c('br'),_c('small',[_vm._v(" "+_vm._s(_vm.$format.decimal(item.product_variants[0].simples_nacional))+" ")])]}},{key:"item.new_commission",fn:function(ref){
var item = ref.item;
return [_c('b',{staticClass:"success--text"},[_vm._v(" "+_vm._s(_vm.$format.decimal(item.new_commission))+" ")]),_c('br'),_c('small',[_vm._v(" "+_vm._s(_vm.$format.decimal(item.product_variants[0].commission))+" ")])]}},{key:"item.new_price_cash",fn:function(ref){
var item = ref.item;
return [_c('b',{staticClass:"success--text"},[_vm._v(" "+_vm._s(_vm.$format.decimal(item.new_price_cash))+" ")]),_c('br'),_c('small',[_vm._v(" "+_vm._s(_vm.$format.decimal(item.product_variants[0].price_cash))+" ")])]}}],null,true)})],1)],1)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }