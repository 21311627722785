<template>
  <div>
    <v-btn
      @click="handleXlsxClick()"
      class="text-capitalize"
      outlined
      color="primary"
    >
      <v-icon> mdi-file-table-outline </v-icon>
      <span class="ml-2">{{ label }}</span>
    </v-btn>

    <input
      class="d-none"
      ref="fileInput"
      type="file"
      accept=".xlsx"
      @input="handleFileInput()"
    />
  </div>
</template>

<script>
export default {
  props: {
    url: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    fileXlsx: [],
    fileName: null,
    useProductPrice: false,
  }),

  computed: {
    label() {
      return this.xlsxName == null ? "Abrir arquivo XLSX" : this.xlsxName;
    },
  },

  methods: {
    handleXlsxClick() {
      this.$refs.fileInput.click();
    },

    handleFileInput() {
      let input = this.$refs.fileInput;
      let files = input.files;
      if (files && files[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.fileXlsx = files[0];
          this.fileName = files[0].name;
          this.parseXlsx();
        };
        reader.readAsDataURL(files[0]);
      }
    },

    async parseXlsx() {
      this.$loading.start();

      let form = new FormData();

      form.append("xlsx", this.fileXlsx, "xlsx");

      await this.$http
        .store(this.url, form)
        .then((response) => {
          this.$emit("input", response);

          this.$loading.finish();
        })
        .catch((error) => {
          this.xlsxError = true;
          this.$loading.finish();
        });
    },
  },
};
</script>

<style>
</style>